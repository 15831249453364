import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

/**
 * app.ts
 * import { register } from 'swiper/element/bundle';
 * constructor() { register()}
 * 
 * <swiper-container swiper init="false" [swiperOptions]="options">
 */

@Directive({
    selector: '[swiper]',
    standalone: true,
  })
export class SwiperDirective implements AfterViewInit {
    @Input() swiperOptions?: SwiperOptions;
    
    constructor(
        private el: ElementRef<SwiperContainer>
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestory(): void {
        this.el.nativeElement.remove()
    }

    ngAfterViewInit(): void {
        Object.assign(this.el.nativeElement, this.swiperOptions);

        this.el.nativeElement.initialize();
    }
}